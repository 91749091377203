'use strict';

$(function() {
  const adventuresContent = [
    {
      namePlace: "Клондайк, Канада",
      image: "/static/img/content/test/klondike.jpg",
      question: "Кого следовало опасаться золотоискателю и его верному псу в глуши лесов Юкона больше всего?",
      answers: [
        {
          answer: "Голодного медведя"
        },
        {
          answer: "Лосей - в период миграции они бежали сломя голову и топтали все на своем пути"
        },
        {
          answer: "Йети"
        },
        {
          answer: "Возмущенных канадских индейцев - ихетов"
        }
      ],
      description: "Бэк метался с такой непостижимой быстротой, а индейцы сбились в такую тесную кучу, что они своими стрелами поражали не его, а друг друга. Тут ихетов охватил панический ужас, и они бросились бежать в лес, крича, что на них напал злой дух.",
      correctAnswer: 4
    },
    {
      namePlace: "р. Юкон, Канада",
      image: "/static/img/content/test/yukon.jpg",
      question: "В свой первый переход между Дайей и Доусоном в почтовой упряжке собак главной опасностью для Бэка и остальных было следующее:",
      answers: [
        {
          answer: "Соперники золотоискателей, который маскировали под снегом железные шипы, о которые собаки в кровь ранили лапы и не могли продолжать бежать"
        },
        {
          answer: "Шайка разбойников, грабивших всех проезжавших мимо"
        },
        {
          answer: "Осенью лед еще не встал, и собаки, бегущие вверх по течению реки, вместе с погонщиками могли провалиться в воду Юкона"
        },
        {
          answer: "Поломка полозьев нарт о выступающие из-под земли корни деревьев и камни: это грозило непозволительной задержкой"
        }
      ],
      description: "Вскоре нарты вместе с Дэйвом и Бэком провалились в воду, собаки чуть не захлебнулись, и их вытащили полумертвыми. Для спасения их жизни пришлось разжечь костер. Они были покрыты толстой ледяной корой, и, для того чтобы она оттаяла, Франсуа и Перро заставили их бегать вокруг костра так близко к огню, что он опалял на них шерсть.",
      correctAnswer: 3
    },
    {
      namePlace: "р. Стьюарт, Канада",
      image: "/static/img/content/test/stewart.jpg",
      question: "Какое природное явление чаще и сильнее всего досаждало Бэку во время его путешествия с хозяином в поисках золота?",
      answers: [
        {
          answer: "Снежные бураны"
        },
        {
          answer: "Гололедица"
        },
        {
          answer: "Крупный град"
        },
        {
          answer: "Грозы с молниями"
        }
      ],
      description: "Проходили  месяцы,  а  они  все  бродили  среди  диких  просторов  этой неисследованной  земли,  где не было людей, но где  когда-то побывали люди, если   верить  легенде  о  покинутой   хижине.  Переходили   горные хребты, разделявшие  реки,  и не раз их здесь застигали снежные бураны.",
      correctAnswer: 1
    },
    {
      namePlace: "р. Фортимайл (Золотая миля), Канада",
      image: "/static/img/content/test/forty-mile.jpg",
      question: "Единственным человеком, которого Бэк горячо и преданно любил, был золотоискатель Джон Торнтон. Как пес однажды спас ему жизнь?",
      answers: [
        {
          answer: "Отбил раненого хозяина у стаи волков"
        },
        {
          answer: "Во время переправы через реку спас хозяина от смерти при сплаве через опасные пороги"
        },
        {
          answer: "Согрел Торнтона, когда ночь застигла их во время метели"
        },
        {
          answer: "Обнаружил золотую россыпь, когда Торнтон отчаялся расплатиться с долгами"
        }
      ],
      description: "Лодка понеслась по течению и попала в водоворот, где вода бурлила, как под мельничным колесом. Ганс растерялся и вместо того, чтобы дать лодке ход, натянул веревку и сделал это так быстро, что лодка перевернулась дном кверху и Торнтон вывалился из нее в пучину. Его понесло в такой водоворот, с которым не справился бы и самый опытный пловец. Бэк тотчас же прыгнул в воду. Проплыв сотни три ярдов по стремительному потоку, он, наконец, добрался до Торнтона.",
      correctAnswer: 2
    },
    {
      namePlace: "г. Доусон, Канада",
      image: "/static/img/content/test/douson.jpg",
      question: "Вся жизнь в Клондайке концентрировалась в городе Доусон. Сюда стекались прибывшие из Америки золотоискатели и авантюристы, здесь они жили и отсюда отправлялись пытать счастья в поиске заветного драгоценного песка. А какой подвиг здесь совершил Бэк?",
      answers: [
        {
          answer: "В одиночку сдвинул с места и провез по снегу нарты, нагруженные грузом в 500 кг"
        },
        {
          answer: "Спас маленькую девочку от волка"
        },
        {
          answer: "Вовремя успел доставить лекарство от дифтерии, возглавив упряжку собак и предотвратив этим эпидемию"
        },
        {
          answer: "Быстрее всех остальных ездовых собак преодолел путь до Доусона"
        }
      ],
      description: "Бэк рванулся вперед, сильно натянув постромки. Все его тело подобралось в страшном усилии, мускулы выперли узлами и ходили под шерстью, как живые. Но нарты уже стремительно задергались и, больше не застревая на месте, толчками двинулись вперед. Толчки заметно выравнивались, и когда нарты, преодолев наконец инерцию, набрали скорость, Бэк подхватил их и повез. Люди тяжело переводили дух, не сознавая, что за минуту перед тем они не дышали. Расстояние было измерено заранее, и, когда Бэк достиг вязанки дров, брошенных на то место, где кончались сто ярдов, волнение прорвалось наружу и превратилось в рев толпы. У вязанки Бэк остановился по команде. Все точно с ума сошли от восторга. Шапки и рукавицы полетели в воздух.",
      correctAnswer: 1
    },
    {
      namePlace: "оз. Ле-Барж («Лаберж»), Канада",
      image: "/static/img/content/test/laberg.jpg",
      question: "На пути к Доусону почтовая упряжка собак, в которую попал Бэк, пересекала множество горных хребтов и озер. Какая смертельная опасность подстерегала их на регулярных привалах?",
      answers: [
        {
          answer: "Нехватка пайка на всех ездовых собак"
        },
        {
          answer: "Драка с бешеными собаками"
        },
        {
          answer: "Гибель от холода в снегу во время ночевки"
        },
        {
          answer: "Крысиная отрава, брошенная прежними посетителями на месте ночевки"
        }
      ],
      description: "Глаза у них горели, с клыков текла пена; обезумевшие от голода, они были страшны, неодолимы. В первой же схватке ездовые собаки были отброшены к скале. На Бэка напали сразу три чужих пса и в один миг изгрызли ему плечи и морду. Шум стоял ужасающий.",
      correctAnswer: 2
    },
    {
      namePlace: "г. Дайи, Аляска, США",
      image: "/static/img/content/test/daji.jpg",
      question: "С поселка Дайи (ныне заброшенного) начинался сложный путь на Клондайк, которым шли золотоискатели. Туда привезли и Бэка, которому предстояло пройти этим маршрутом не один раз. А что в Дайе случилось с Бэком впервые?",
      answers: [
        {
          answer: "Его впервые ударил человек"
        },
        {
          answer: "Он впервые подрался с другими собаками"
        },
        {
          answer: "Он в первый раз попробовал сырую рыбу"
        },
        {
          answer: "Он впервые увидел снег"
        }
      ],
      description: "Бэк почувствовал, что его лапы погрузились в какую-то кашу, очень похожую на белую грязь. Он зафыркал и отскочил назад. Такая же белая каша падала сверху. Бэк отряхнулся, но она все сыпалась и сыпалась на него. Он с любопытством понюхал ее, потом лизнул языком. Она обжигала, как огонь, и сразу таяла на языке. Это поразило Бэка, он лизнул опять - с тем же результатом. Так Бэк впервые увидел снег.",
      correctAnswer: 4
    },
    {
      namePlace: "Пороги Пяти Пальцев, Канада",
      image: "/static/img/content/test/five-finger.jpg",
      question: "Попав к новым хозяевам в новую упряжку, Бэк сразу понял, что они понятия не имели, как выживать на Севере. В суровых условиях Юкона эта неопытность и самонадеянность молодых авантюристов привела к беде. К какой? ",
      answers: [
        {
          answer: "Хозяева не рассчитали паек, и собакам пришлось голодать"
        },
        {
          answer: "Они не позаботились о прививках для собак, и те схватили чумку"
        },
        {
          answer: "Злясь на свою беспомощность, они до смерти избивали собак кнутом"
        },
        {
          answer: "Вместо того, чтобы отправиться в путешествие по льду реки, они отправились в обход через горы и разбили нарты"
        }
      ],
      description: "К тому времени, как они дошли до Пяти Пальцев (пороги Пять Пальцев), запасы собачьего провианта кончились. Они превратились в ходячие скелеты. Они были так измучены, что уже стали нечувствительны к ударам бича и дубинки. Боль от побоев ощущалась ими как-то тупо, и они все видели и слышали словно издалека. Это были уже полумертвые существа, попросту мешки с костями, в которых жизнь едва теплилась.",
      correctAnswer: 1
    },
    {
      namePlace: "пос. Скагуэй, Аляска, США",
      image: "/static/img/content/test/skaguway.jpg",
      question: "Скагуэй был основан во время «золотой лихорадки» как начальный пункт пути через горный перевал к золотым приискам бассейна реки Клондайк. Сюда собаки возвращались из Доусона, забирали почту и отправлялись в обратный путь. Какое препятствие однажды сильно затруднило их привычный маршрут? ",
      answers: [
        {
          answer: "Собаки передрались между собой за право быть лидером упряжки и бежали медленно и плохо"
        },
        {
          answer: "Собаки заболели бешенством"
        },
        {
          answer: "Все собаки в упряжке смертельно устали и им требовался длительный отдых"
        },
        {
          answer: "Упряжка потеряла своего постоянного погонщика Перро, которого застрелили во время драки"
        }
      ],
      description: "Лапы у всех были ужасно стерты, утратили всю свою подвижность и упругость и ступали так тяжело, что тело сотрясалось и собаки уставали вдвойне. Все дело было в этой смертельной усталости от постепенного и длительного истощения физической энергии в течение многих месяцев тяжкого труда. У собак уже не осталось никакого запаса сил и никакой способности к их восстановлению: силы были использованы все до последней крупицы, каждый мускул, каждая жилка, каждая клеточка тела смертельно утомлены.",
      correctAnswer: 3
    },
    {
      namePlace: "Санта-Клара, Калифорния",
      image: "/static/img/content/test/santa-clara.jpg",
      question: "Первые четыре года своей жизни пес Бэк провел в солнечной Калифорнии. С кем и где он жил?",
      answers: [
        {
          answer: "Он жил у садовника, который состоял на службе при богатой усадьбе, и охранял всю территорию поместья"
        },
        {
          answer: "Нигде конкретно он не жил – Бэк был бродячим псом"
        },
        {
          answer: "Бэк жил в просторном поместье в семье судьи, и был главным псом во всей усадьбе"
        },
        {
          answer: "Он был комнатной изнеженной собакой и практически не выходил на улицу"
        }
      ],
      description: "Но Бэк не был ни комнатной собачкой, ни дворовым псом. Вся усадьба была в его распоряжении. Он плавал в бассейне и ходил на охоту с сыновьями судьи. Он сопровождал его дочерей, Молли и Алису, когда они в сумерки или ранним утром отправлялись на прогулку. В зимние вечера он лежал у ног судьи перед пылающим камином в библиотеке. Он был королем, властителем над всем, что ползало, бродило и летало в поместье судьи Миллера, включая и его двуногих обитателей.",
      correctAnswer: 3
    }
  ];

  $('.map__pin').each(function (i, it) {
    $(it).attr('data-pin', i);
  });

  function createQuestion(id) {
    const answersWrap = $(".test__answers");
    const imgData = adventuresContent[id].image;
    const placeData = adventuresContent[id].namePlace;
    const questionData = adventuresContent[id].question;
    $('.adventures__img img').attr('src', imgData);
    $(".test__question").text(questionData);
    $(".adventures__place-name").text(placeData);
    answersWrap.html("");
    renderAnswers(adventuresContent[id].answers, answersWrap);
  }

  function renderAnswers(answers, wrap) {
    for (let i = 0; i < answers.length; i++) {
      let elem = `<div class='test__answers-item' data-answer=${i + 1}><div class='answer-checkbox'></div><p class='answer'>${answers[i].answer}</p></div>`;
      $(wrap).append(elem);
    }

    $('.test__answers-item').on("click", function() {
      ga('send', 'event', 'Click', 'Map', 'Answer');
      const id = $('.adventures__test-content').attr('data-test');
      onAnswerClick($(this), id);
      renderQuote(id);
      setTimeout(() => {
        $('.adventures__test-content').fadeOut(1).find('.test').hide();
        $('.adventures__test-content').fadeIn(700).find('.test-desc').show();
      }, 3000);
    });
  }

  function renderQuote(id) {
    const quoteWrap = $(".test-desc__quote blockquote p");
    const descData = adventuresContent[id].description;
    quoteWrap.text(descData);
  }

  $('.map__pin').on('click', function() {
    $('.test .btn-close').css('pointer-events', 'initial');
    $(this).addClass('is-active');
    const id = $(this).attr('data-pin');
    $('.adventures__test-content').attr('data-test', id);
    if(id < adventuresContent.length) {
      createQuestion(id);
      $(".adventures__map-content").fadeOut(1);
      $('.adventures__test-content').fadeIn(700).find('.test').show();
    }
  });

  $('.test-desc .btn-close').on('click', onBtnClose);
  $('.test .btn-close').on('click', function() {
    $(".test__answers").html("");
    $('.adventures__test-content').fadeOut(1).find('.test').hide();
    $(".adventures__map-content").fadeIn(700).show();
  });

  let isAnswer = false;

  function onAnswerClick(elem, id) {
    const dataAnswer = parseInt(elem.attr("data-answer"));
    const currentAnswer = adventuresContent[id].correctAnswer;

    if (!isAnswer) {
      isAnswer = true;
      if (dataAnswer === currentAnswer) {
        $(elem).addClass('is-correct');
      } else {
        $('.test__answers-item').eq(currentAnswer - 1).addClass('is-correct');
        $(elem).addClass('is-wrong');
      }
    }
    $('.test .btn-close').css('pointer-events', 'none');
    $('.test__answers-item').css('pointer-events', 'none');
  };

  function onBtnClose() {
    isAnswer = false;
    $(".test__answers").html("");
    $(".test-desc__quote blockquote p").html("");
    $('.adventures__test-content').fadeOut(1).find('.test-desc').hide();
    $(".adventures__map-content").fadeIn(700).show();
  };

  $('.btn--about').on('click', function () {
    let offsetTop = $('#about-film').offset().top;
    $('html, body').animate({
      scrollTop: offsetTop
    }, 500);
  });

  $('.share-link__list').socialLikes();

  $('.share-link__item').on('click', function() {
    let shareBtnClass = $(this).attr('class').split(' ')[1];
    switch (shareBtnClass) {
      case 'fb':
        ga('send', 'event', 'Share', 'Share_Click', 'FB_Share');
        break;
      case 'vk':
        ga('send', 'event', 'Share', 'Share_Click', 'VK_Share');
        break;
      case 'ok':
        ga('send', 'event', 'Share', 'Share_Click', 'OK_Share');
        break;
      case 'telegram':
        ga('send', 'event', 'Share', 'Share_Click', 'TG_Share');
        break;
      case 'tw':
        ga('send', 'event', 'Share', 'Share_Click', 'TW_Share');
        break;
    }
  });

  $('.btn--about').on('click', function () {
    ga('send', 'event', 'Click', 'Button_Click', 'About');
  });

  $('.btn.is-desktop, .btn.is-mobile').on('click', function () {
    ga('send', 'event', 'Click', 'Button_Click', 'GetTicket');
  });

  $('.map__pin').on('click', function () {
    ga('send', 'event', 'Click', 'Map', 'Marker');
  });
});
